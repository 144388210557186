import React, { useEffect } from 'react';
import { useMoralis } from "react-moralis";
import { useMoralisWeb3Api } from "react-moralis";
import { Wallet } from 'react-bootstrap-icons';

import Button from 'react-bootstrap/Button';

function LoginButton({ setNFTsList }) {
  const Web3Api = useMoralisWeb3Api();

  const fetchNFTs = async () => {
    // get NFTs for current user on Mainnet
    const userEthNFTs = await Web3Api.account.getNFTs();
    // get testnet NFTs for user
    // const testnetNFTs = await Web3Api.Web3API.account.getNFTs({
    //   chain: "mumbai",
    // });
    // console.log(testnetNFTs);
  };
    const { authenticate, isAuthenticated, isAuthenticating, user, account, logout } = useMoralis();
    useEffect(() => {
      if (isAuthenticated) {
        // add your logic here
        fetchNFTs()
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    const login = async () => {
      if (!isAuthenticated) {

        await authenticate({signingMessage: "Log in using Moralis" })
          .then(function (user) {
            console.log("logged in user:", user);
            console.log(user!.get("ethAddress"));
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }

    const logOut = async () => {
      await logout();
      setNFTsList([])
      console.log("logged out");
    }
  return (
    <>
      <div></div>
      {!isAuthenticated
        ? (<Button variant="success" onClick={login}>
          <Wallet size={25} /> Connect Wallet
        </Button>)
        : <Button variant="danger" onClick={logOut}>Logout</Button>}
    </>
  );
}

export default LoginButton;