import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';

import logo from '../../logo.png'
import LoginButton from '../LoginButton/index.tsx';

import './Header.css'

function Header({ setNFTsList }) {

  return (
    <div className='Header'>
      <LoginButton setNFTsList={setNFTsList} />
    </div>
  );
}

export default Header;