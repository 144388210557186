import { useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import _uniqBy from 'lodash/uniqBy'
import _get from 'lodash/get'
import { Link, Asterisk, ArrowLeftCircleFill, Layers } from 'react-bootstrap-icons';

import MailChimpSub from '../components/MailChimpSub/index.tsx'
import './ProjectDetails.css'

import * as benefitsJSON from '../data/benefits.json'

const ProjectDetails = ({ NFTsList }) => {
  const navigate = useNavigate();
  let { id } = useParams();
  const projectData = _get(benefitsJSON, ['default', id])
  if (!projectData || !projectData.imageURL) {
    return (
      <div>
        <div className="ProjectDetails-Back">
          <Button onClick={() => navigate.push('/')}>Back</Button>
        </div>
        <h3>
          Sorry, We don't support this NFT at the moment.<br />
          <a target="_blank" href={`https://docs.google.com/forms/d/e/1FAIpQLSe_E6K_aCU3_zPTDWPtFoJwFFg5NtUEtPJQIxfSSN3Dt5cdTw/viewform?usp=pp_url&entry.319428828=${id}`}>Please click here to fill out the form if you want us to add it</a>
        </h3>
      </div>
    )
  }
  return (
    <div className="ProjectDetails">
      <div className="ProjectDetails-Back">
        <ArrowLeftCircleFill onClick={() => navigate('/')} size={36} />
      </div>

      <h5 style={{ margin: '30px 0', padding: '15px', border: '1px solid #5A2A9B', borderRadius: '20px' }}>
        Do you See anything missing? <br/>
        <a style={{ color: '#5A2A9B' }}
          target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSetPefhP28uAAXMZQHAwYfmR69myfRojJCugIPUJjnzEmaXFg/viewform?usp=sf_link">
          Please click here and fill the form we will take a look at it
        </a>
      </h5>
      <div className='ProjectDetails-content'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className='ProjectDetails-descriptionCard'>
            <img width={200} height={200} src={projectData.imageURL} />
            <div>
              <h1>{projectData.name}</h1>
              <h5>Created by {projectData.createdBy}</h5>
              <div className='ProjectDetails-description'>{projectData.description}</div>
            </div>
          </div>
          <div className="ProjectDetails-updates">
            <h1>Updates</h1>
            <div>There is nothing that you are missing :)</div>
          </div>
        </div>
        <br/>
        <div className="ProjectDetails-benefits">
          <h1 style={{ textAlign: 'left' }}>
            <Layers size={25} /> Benefits
          </h1>
          <br/>
          <ul style={{ padding: 0 }}>
            {projectData?.benefits.map(({ name, description,source }) => {
              return (<li className='ProjectDetails-Item'>
                  <span style={{ display: 'flex', alignItems: 'center', color: '#1E3964' }}>
                    <Asterisk size={16} color="#1E3964" style={{ marginRight: '10px', width: 25 }}/>
                    <div>{description ? `${name} - ${description}` : name}</div>
                  </span>
                  <a href={source}><Link size={26} color="#5A2A9B" /></a>
              </li>)
            })}
          </ul>
          <br />
          <MailChimpSub />
        </div>
      </div>
    </div>
  );
}

export default ProjectDetails;
