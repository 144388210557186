import React, { useEffect } from 'react';
import { Link } from "react-router-dom";

import benefitsJSON from '../../data/benefits.json'
import opensea from './opensea.png'
import './ProjectCard.css'

const defaultImg = 'https://i.insider.com/6123e07e4932030018457fb7?width=1136&format=jpeg'

function ProjectCard(props) {
  const { symbol, name } = props

  return (
    <div className='ProjectCard'>
      <Link to={`/${symbol}`}>
        <div className='ProjectCard-circle-benefit'>
          <img src={benefitsJSON[symbol]?.imageURL || defaultImg} />
        </div>
        <div className="ProjectCard-opensea">
          <img src={opensea} />
        </div>
        <div>
          {`${name}`}
        </div>
      </Link>
    </div>
  );
}

export default ProjectCard;