import './App.css';
import { useEffect, useState } from 'react';
import { useMoralis } from "react-moralis";
import { useMoralisWeb3Api } from "react-moralis";
import { Routes, Route, Navigate } from "react-router-dom";

import _get from 'lodash/get'
import _size from 'lodash/size'

import LoginButton from './components/LoginButton/index.tsx';
import Header from './components/Header/index.tsx';
import YourProjects from './pages/YourProjects.tsx';
import LandingPage from './pages/LandingPage.tsx';
import ProjectDetails from './pages/ProjectDetails.tsx';

const status = {
  SYNCED: 'SYNCED',
}

const ProtectedRoute = ({
  redirectPath = '/',
  children,
}) => {
  const { isAuthenticated, isInitialized } = useMoralis();
  if (isInitialized && !isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

function App() {
  const [NFTsList, setNFTsList] = useState([])
  const { isAuthenticated, isInitialized } = useMoralis();

  const Web3Api = useMoralisWeb3Api();

  const fetchNFTs = async () => {
    // get NFTs for current user on Mainnet
    const userEthNFTs = await Web3Api.account.getNFTs();
    if(_get(userEthNFTs, 'status') === status.SYNCED) {
      setNFTsList(_get(userEthNFTs, 'result'))
    }
  };

  useEffect(() => {
    if (isInitialized && isAuthenticated) {
      fetchNFTs()
    }
  }, [isInitialized, isAuthenticated])

  return (
    <div className="App">
      {/* {(isAuthenticated && _size(NFTsList) > 0) &&
      <YourProjects NFTsList={NFTsList} />} */}
      <Header setNFTsList={setNFTsList} />
      <div className='App-container'>
        <Routes>
          <Route path="/:id" element={
            <ProtectedRoute>
              <ProjectDetails />
            </ProtectedRoute>} />
          <Route path="/" element={<YourProjects NFTsList={NFTsList} />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
