import React, { useEffect } from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe"

import Button from 'react-bootstrap/Button';
import './MailChimpSub.css'

const mailChimpURL = "https://gmail.us11.list-manage.com/subscribe/post?u=4b9cd4e2a3c5787c99b5afcf6&amp;id=5bf15d3ef8"
function MailChimpSub() {
  return (
    <div className='MailChimpSub'>
      <h3>Wanna get updates on your email every time new benefit is added?</h3>
      <h5>Sign up for our UPCOMING Benefits Alert!</h5>
      <div className='MailChimpSub-form'>
        <MailchimpSubscribe url={mailChimpURL} />
      </div>
    </div>
  );
}

export default MailChimpSub;