import { useEffect } from 'react';
import { useMoralis } from "react-moralis";
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from "react-router-dom";

import _uniqBy from 'lodash/uniqBy'
import ProjectCard from '../components/ProjectCard/index.tsx'
import benefitsJSON from '../data/benefits.json'

import './YourProjects.css'

const YourProjects = ({ NFTsList }) => {
  return (
    <div className="YourProjects">
      <h2>NFTs in Your Wallet</h2>
      <ul className='YourProjects-list'>
        {_uniqBy(NFTsList, 'symbol').map(({ name, symbol }) => {
          return (
            <ProjectCard name={name} symbol={symbol} />
          )
        })}
      </ul>
    </div>
  );
}

export default YourProjects;
